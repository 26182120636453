import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { useSyncSidebarScroll } from "#Root/hooks/useSyncSidebarScroll.js"
import KeyboardShortcut from "#Root/ui/KeyboardShortcut"

import Link from "./link.jsx"

const childrenArray = (children) => {
  let result = []
  if (Array.isArray(children)) {
    for (const child of children) {
      result = result.concat(childrenArray(child))
    }
  } else if (children) {
    result.push(children)
  }
  return result
}

const isActiveOrHasActiveChildren = (props) => {
  const path = window.location.pathname
  const search = window.location.search
  const { href, fullMatch } = props

  if (props.active === true) {
    return true
  }

  let active = false
  if (fullMatch) {
    active = path === href || path + search === href
  } else {
    // This works like this because the first link can have the bare path like `/performance`
    // That said, we don't nest everything under it. We have `/performance/incidents` and `/performance/traces`. But only the first is a sub-item.
    // Ideally we should redirect plain `/performance` links to `/performance/incidents`
    active =
      (path.startsWith(href) && !path.includes("graphs") && !path.includes("traces")) ||
      path + search == href
  }

  const children = childrenArray(props.children)

  for (const child of children) {
    if (isActiveOrHasActiveChildren(child.props)) {
      active = true
    }
  }

  return active
}

const NavLink = ({ id, title, color, icon, href = "", keyboardShortcut }, active) => {
  const activeClass = active ? "c-side-nav__section--active" : ""
  const { saveScrollOffset } = useSyncSidebarScroll()

  return (
    <li id={id} className={`c-side-nav__section c-side-nav__section--${color} ${activeClass}`}>
      <Link className="c-side-nav__item" href={href} onClick={saveScrollOffset}>
        <i className={`c-side-nav__item-icon py-1 min-w-[17px] fa fa-fw ${icon}`} />
        <span className="c-side-nav__item-title responsive-hidden">{title}</span>
        {keyboardShortcut && (
          <div className="responsive-hidden indent-0">
            <KeyboardShortcut shortcut={keyboardShortcut} className="c-side-nav__item-icon ml-1" />
          </div>
        )}
      </Link>
    </li>
  )
}

const SubMenu = ({ id, title, children }, active) => {
  const getMenuInitialState = () => {
    return active ? true : JSON.parse(localStorage.getItem(`side-menu-sub-${title}`)) || false
  }

  const [isOpen, setIsOpen] = useState(getMenuInitialState())
  const arrow = isOpen ? "fa-angle-up" : "fa-angle-down"

  useEffect(() => {
    localStorage.setItem(`side-menu-sub-${title}`, isOpen)
  }, [isOpen, title, active])

  const handleMenuOpen = (e) => {
    if (e.ctrlKey || e.metaKey) return
    setIsOpen(!isOpen)
  }

  return (
    <li id={id}>
      <div className="c-side-nav__subitem" onClick={handleMenuOpen}>
        <span className="c-side-nav__subitem-title">{title}</span>
        <i className={`c-side-nav__subitem-icon ml-1 far fa-fw ${arrow}`} />
      </div>
      {isOpen && <ul className="ml-0 c-side-nav__subitems">{children}</ul>}
    </li>
  )
}

const Menu = ({ id, title, color, icon, children }, active) => {
  const getMenuInitialState = () => {
    return active ? true : JSON.parse(localStorage.getItem(`side-menu-${title}`)) || false
  }

  const [isOpen, setIsOpen] = useState(getMenuInitialState())
  const arrow = isOpen ? "fa-angle-up" : "fa-angle-down"
  const activeClass = `${isOpen ? "c-side-nav__section--open" : ""} ${
    active ? "c-side-nav__section--active" : ""
  }`

  useEffect(() => {
    localStorage.setItem(`side-menu-${title}`, isOpen)
  }, [isOpen, title, active])

  const handleMenuOpen = (e) => {
    if (e.ctrlKey || e.metaKey) return
    setIsOpen(!isOpen)
  }

  return (
    <li
      id={id}
      className={`c-side-nav__section c-side-nav__section--${color} ${activeClass} cursor-pointer select-none bg-none`}
    >
      <div className="c-side-nav__item" onClick={handleMenuOpen}>
        <i className={`c-side-nav__item-icon py-1 min-w-[17px] fa fa-fw ${icon}`} />
        <span className="c-side-nav__item-title responsive-hidden">{title}</span>
        <div className="responsive-hidden indent-0">
          <i className={`c-side-nav__item-icon far fa-fw ${arrow} ml-1`} />
        </div>
      </div>
      {isOpen && <ul className={"c-side-nav__subitems responsive-sidebar-submenu"}>{children}</ul>}
    </li>
  )
}

const Subitem = ({ id, title, label, href = "" }, active) => {
  const { saveScrollOffset } = useSyncSidebarScroll()

  const activeClass = active ? "c-side-nav__subitem--active" : ""
  return (
    <li id={id}>
      <Link className={`c-side-nav__subitem ${activeClass}`} href={href} onClick={saveScrollOffset}>
        <span className="c-side-nav__subitem-title">
          {title}
          {label && (
            <span className="inline-flex justify-center items-center ml-1 c-pill c-pill--purple w-5 h-5 p-0">
              {label}
            </span>
          )}
        </span>
      </Link>
    </li>
  )
}

const SideNavigation = (props) => {
  const { type } = props
  const active = isActiveOrHasActiveChildren(props)

  const component = {
    link: NavLink(props, active),
    menu: Menu(props, active),
    subitem: Subitem(props, active),
    submenu: SubMenu(props, active),
    dummy: null,
  }

  return component[type] || NavLink(props, active)
}

SideNavigation.propTypes = {
  title: PropTypes.string,
  href: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  keyboardShortcut: PropTypes.string,
}

NavLink.propTypes = SideNavigation.propTypes
Menu.propTypes = SideNavigation.propTypes
Subitem.propTypes = SideNavigation.propTypes
SubMenu.propTypes = SideNavigation.propTypes

export default SideNavigation
