import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["selectAll", "checkbox", "deleteButton"]

  connect() {
    this.updateSelectAllState()
    this.updateDeleteButton()
  }

  toggleAll() {
    const isChecked = this.selectAllTarget.checked
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = isChecked
    })
    this.updateDeleteButton()
  }

  toggleOne() {
    this.updateSelectAllState()
    this.updateDeleteButton()
  }

  confirmDelete(event) {
    const count = this.checkboxTargets.filter(checkbox => checkbox.checked).length
    const message = `Are you sure you want to delete ${count} selected notifier${count === 1 ? "" : "s"}?`
    
    if (!confirm(message)) {
      event.preventDefault()
    }
  }

  updateSelectAllState() {
    const totalCheckboxes = this.checkboxTargets.length
    const checkedCheckboxes = this.checkboxTargets.filter(checkbox => checkbox.checked).length

    this.selectAllTarget.checked = totalCheckboxes > 0 && totalCheckboxes === checkedCheckboxes
  }

  updateDeleteButton() {
    const checkedCount = this.checkboxTargets.filter(checkbox => checkbox.checked).length
    
    if (checkedCount > 0) {
      this.deleteButtonTarget.classList.remove("hidden")
      this.deleteButtonTarget.textContent = `Delete ${checkedCount} notifier${checkedCount === 1 ? "" : "s"}`
    } else {
      this.deleteButtonTarget.classList.add("hidden")
    }
  }
}
