export function replaceEntities(input) {
  return input.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;")
}

export function wildcardSearch(input, query, allowWildcards = false) {
  const sanitizedQuery = allowWildcards
    ? query?.replace(/[/\-\\^$+?.()|[\]{}]/g, "\\$&").replace("*", ".+")
    : query?.replace(/[/\-\\^$+?.()|[\]{}*]/g, "\\$&")
  return new RegExp(sanitizedQuery, "i").test(input)
}

export function pluralizeCount(num) {
  const count = +num

  const suffixes = ["th", "st", "nd", "rd"]
  const value = count % 100

  // Handle 11, 12, 13 (special cases)
  if (value >= 11 && value <= 13) {
    return `${count}th`
  }

  // Use modulus 10 to determine appropriate suffix
  const lastDigit = count % 10
  const suffix = suffixes[lastDigit] || "th"

  return `${count}${suffix}`
}

export function pluralizeSuffix(num) {
  const count = +num
  const value = count % 100

  if (value >= 11 && value <= 13) {
    return "th"
  }

  const suffixes = ["th", "st", "nd", "rd"]
  return suffixes[count % 10] || "th"
}

export function pluralize(count, string) {
  switch (count) {
    case 1:
      return string
    default:
      return `${string}s`
  }
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase().replace(/_/g, " ")
}

export function highlightWord(text, word) {
  if (word && word.length > 0) {
    return text.replace(
      new RegExp(`(${word})`, "g"),
      '<span class="bg-yellow-100 text-yellow-800">$1</span>',
    )
  } else {
    return text
  }
}

export function nonEmpty(value) {
  return typeof value === "string" && value.length > 0
}

export function decamelize(input, sep = "_") {
  if (typeof input !== "string") {
    throw new TypeError("Expected a string")
  }

  return input
    .replace(/([a-z\d])([A-Z])/g, `$1${sep}$2`)
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, `$1${sep}$2`)
    .toLowerCase()
}
