import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["site", "filter", "warningMessage", "loadingFilters", "loadedFilters", "subscription", "subscriptionNamespace"]
  static environmentTally = {}

  initialize() {
    this.environmentTally = {}
    this.selectedPerEnvironmentTally = {}

    this.siteTargets.forEach((site) => {
      const environment = site.dataset.environment
      if (!this.selectedPerEnvironmentTally[environment]) this.selectedPerEnvironmentTally[environment] = 0

      if (site.checked) {
        this.selectedPerEnvironmentTally[environment] += 1
      }

      if (!this.environmentTally[environment]) {
        this.environmentTally[environment] = 0
      }
      this.environmentTally[environment] += 1
    })

    Object.keys(this.environmentTally).forEach((environment) => {
      this.determineFilterSelection(environment)
    })

    this.checkIfAllShouldBeSelected()
    this.checkIfShouldDisplayWarning()
    this.checkIfSubscriptionsShouldBeEnabled()

    this.loadingFiltersTarget.classList.add("hidden")
    this.loadedFiltersTarget.classList.remove("hidden")
  }

  onChange(event) {
    if (event.target.value === "all") {
      this.filterTargets.forEach((filter) => {
        filter.checked = event.target.checked
      })
      this.siteTargets.forEach((site) => site.checked = event.target.checked)
    } else {
      const sites = this.siteTargets.filter((site) => site.dataset.environment === event.target.value)
      if (event.target.checked) {
        sites.forEach((site) => site.checked = true)
        this.selectedPerEnvironmentTally[event.target.value] = sites.length
      } else {
        sites.forEach((site) => site.checked = false)
        this.selectedPerEnvironmentTally[event.target.value] = 0
      }
    }

    this.checkIfAllShouldBeSelected()
    this.checkIfShouldDisplayWarning()
  }

  onSiteSelect(event) {
    if (event.target.checked) {
      this.selectedPerEnvironmentTally[event.target.dataset.environment] += 1
      this.determineFilterSelection(event.target.dataset.environment)
    } else {
      this.selectedPerEnvironmentTally[event.target.dataset.environment] -= 1
      this.element.querySelector(`input[value="${event.target.dataset.environment}"]`).checked = false
      this.element.querySelector("input[value=\"all\"]").checked = false
    }

    this.checkIfAllShouldBeSelected()
    this.checkIfShouldDisplayWarning()
  }

  onSubscriptionSelect() {
    this.checkIfSubscriptionsShouldBeEnabled()
  }

  determineFilterSelection(environment) {
    if (this.selectedPerEnvironmentTally[environment] === this.environmentTally[environment]) {
      this.element.querySelector(`input[value="${environment}"]`).checked = true
    }
  }

  checkIfAllShouldBeSelected() {
    this.element.querySelector("input[value=\"all\"]").checked = this.siteTargets.filter((site) => site.checked).length === this.siteTargets.length
  }

  checkIfShouldDisplayWarning() {
    if (this.siteTargets.filter((site) => site.checked).length === 0) {
      this.warningMessageTarget.classList.remove("hidden")
    } else {
      this.warningMessageTarget.classList.add("hidden")
    }
  }

  checkIfSubscriptionsShouldBeEnabled() {
    const allNamespaces = this.subscriptionNamespaceTargets
    this.subscriptionTargets.forEach((subscription) => {
      allNamespaces.filter((namespace) => namespace.dataset.subscription === subscription.value).forEach((namespace) => {
        if (subscription.checked) {
          namespace.disabled = false
        } else {
          namespace.disabled = true
        }
      })
    })
  }
}
