import PropTypes from "prop-types"
import React from "react"

import DateRangePicker from "#Root/ui/DateRangePicker"

import TimeFrameSelector from "./TimeFrameSelector"

const DateTimeFilter = ({
  handleChange,
  initialValue,
  hideCustomRange = false,
  timeframes,
  button,
  hideMinutely = false,
}) => {
  const [currentValue, setCurrentValue] = React.useState({
    timeframe: initialValue.timeframe,
    from: initialValue.timeframe === "custom" ? new Date(initialValue.from) : null,
    to: initialValue.timeframe === "custom" ? new Date(initialValue.to) : null,
  })

  const onChange = (value) => {
    setCurrentValue(value)
    handleChange(value)
  }

  return (
    <div className="c-button-group self-center" data-testid="dateTimeFilter">
      <TimeFrameSelector onChange={onChange} currentValue={currentValue} timeframes={timeframes} />
      {!hideCustomRange && (
        <DateRangePicker
          onChange={onChange}
          value={currentValue}
          button={button || <DateRangePicker.Button />}
          hideMinutely={hideMinutely}
        ></DateRangePicker>
      )}
    </div>
  )
}

DateTimeFilter.propTypes = {
  handleChange: PropTypes.func.isRequired,
  hideCustomRange: PropTypes.bool,
  timeframes: PropTypes.object,
  initialValue: PropTypes.shape({
    timeframe: PropTypes.string.isRequired,
    from: PropTypes.string,
    to: PropTypes.string,
  }),
  button: PropTypes.node,
  hideMinutely: PropTypes.bool,
}

export default DateTimeFilter
