import dayjs from "dayjs"
import React from "react"

import cn from "#Root/utils/cn"

const DateRangeSlider = ({
  windowSize = 8,
  totalHours = 24,
  cellWidth = 8,
  onSelect,
  initialRange,
}) => {
  const [startIndex, setStartIndex] = React.useState(() => {
    if (!initialRange) {
      return 0
    }

    const now = dayjs()
    const initialStart = dayjs(initialRange[0])
    const hoursDiff = now.diff(initialStart, "hour")

    const index = totalHours - 1 - hoursDiff
    return Math.max(0, Math.min(index, totalHours - windowSize))
  })

  const [isDragging, setIsDragging] = React.useState(false)
  const [dragOffset, setDragOffset] = React.useState(0)

  const containerRef = React.useRef(null)
  const previousSelectionRef = React.useRef(null)

  // Generate timestamps for all hours
  const timePoints = React.useMemo(() => {
    const now = dayjs()
    return Array.from({ length: totalHours }, (_, index) => {
      return now.subtract(totalHours - 1 - index, "hour")
    })
  }, [totalHours])

  const selectedRange = React.useMemo(() => {
    if (!timePoints.length) return { start: null, end: null }

    const start = timePoints[startIndex]
    const endIndex = Math.min(startIndex + windowSize, timePoints.length - 1)
    const end = timePoints[endIndex]

    return { start, end }
  }, [timePoints, startIndex, windowSize])

  const renderSelectedRange = () => {
    if (!selectedRange.start || !selectedRange.end) return ""

    const now = dayjs()
    const startFormat = selectedRange.start.isSame(now, "day") ? "HH:mm" : "MMM D, HH:mm"
    const endFormat = selectedRange.end.isSame(now, "day") ? "HH:mm" : "MMM D, HH:mm"

    if (windowSize === 1) {
      return `${selectedRange.start.format("HH:00")} – ${selectedRange.end.format("HH:mm")}`
    }

    return `${selectedRange.start.format(startFormat)} – ${selectedRange.end.format(endFormat)}`
  }

  const handleSelection = React.useCallback(() => {
    if (!onSelect || !selectedRange.start || !selectedRange.end) return

    /* eslint-disable indent */
    const newSelection =
      windowSize === 1
        ? [
            selectedRange.start.startOf("hour").toISOString(),
            selectedRange.end.startOf("minute").toISOString(),
          ]
        : [
            selectedRange.start.startOf("minute").toISOString(),
            selectedRange.end.startOf("minute").toISOString(),
          ]
    /* eslint-enable */

    if (
      !previousSelectionRef.current ||
      previousSelectionRef.current[0] !== newSelection[0] ||
      previousSelectionRef.current[1] !== newSelection[1]
    ) {
      previousSelectionRef.current = newSelection
      onSelect(newSelection)
    }
  }, [onSelect, selectedRange, windowSize])

  const handleMouseDown = (e) => {
    e.preventDefault()
    const container = containerRef.current
    if (!container) return

    const containerRect = container.getBoundingClientRect()
    const mouseX = e.clientX - containerRect.left
    const offset = mouseX - startIndex * cellWidth

    setDragOffset(offset)
    setIsDragging(true)
  }

  const handleMouseUp = () => {
    if (isDragging) {
      setIsDragging(false)
      handleSelection()
    }
  }

  const handleMouseMove = (e) => {
    if (!isDragging || !containerRef.current) return

    const container = containerRef.current
    const containerRect = container.getBoundingClientRect()
    const mouseX = e.clientX - containerRect.left
    const adjustedX = mouseX - dragOffset
    const newStartIndex = Math.floor(adjustedX / cellWidth)
    const boundedStartIndex = Math.max(0, Math.min(newStartIndex, totalHours - windowSize))

    setStartIndex(boundedStartIndex)
  }

  React.useEffect(() => {
    const handleGlobalMouseUp = () => {
      if (isDragging) {
        setIsDragging(false)
        handleSelection()
      }
    }

    window.addEventListener("mouseup", handleGlobalMouseUp)
    return () => window.removeEventListener("mouseup", handleGlobalMouseUp)
  }, [isDragging, handleSelection])

  React.useEffect(() => {
    handleSelection()
  }, [windowSize, handleSelection])

  return (
    <div
      className="w-full"
      style={{ maxWidth: totalHours * cellWidth }}
      ref={containerRef}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      data-testid="date-range-slider"
    >
      <div className="flex items-center h-[26px] rounded-md relative border border-gray-200">
        <div className="w-full flex items-center h-full divide-x divide-gray-200">
          {timePoints.map((_, index) => (
            <div key={index} style={{ width: cellWidth }} className="flex-shrink-0 h-full"></div>
          ))}
        </div>

        <div
          data-testid="date-range-handle"
          className={cn(
            "absolute h-full bg-blue-200 bg-opacity-80 cursor-move border border-blue-300 pointer-events-auto flex justify-center items-center",
            {
              "rounded-l-md": startIndex === 0,
              "rounded-r-md": startIndex === totalHours - windowSize,
            },
          )}
          style={{
            left: startIndex * cellWidth - 1,
            width: windowSize * cellWidth + 1,
          }}
          onMouseDown={handleMouseDown}
          role="slider"
          aria-valuemin={0}
          aria-valuemax={totalHours - windowSize}
          aria-valuenow={startIndex}
        >
          {windowSize >= 4 ? <i className="far fa-arrows-alt-h text-blue-500"></i> : null}
        </div>
      </div>
      <div className="flex justify-center mt-2">
        <span className="text-xs font-medium text-gray-700" data-testid="selected-range">
          {renderSelectedRange()}
        </span>
      </div>
    </div>
  )
}

DateRangeSlider.propTypes = {
  windowSize: PropTypes.number,
  totalHours: PropTypes.number,
  cellWidth: PropTypes.number,
  onSelect: PropTypes.func,
  initialRange: PropTypes.array,
}

export default DateRangeSlider
