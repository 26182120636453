import { useState } from "react"

import { useRestClientContext } from "#Root/contexts/RestClientContext"

export const useRestMetricsList = ({ variables }) => {
  const restApiClient = useRestClientContext()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)

  const variablesString = JSON.stringify(variables)
  React.useEffect(() => {
    setLoading(true)
    const applicableVariables = JSON.parse(variablesString)
    const abortController = new AbortController()
    const signal = abortController.signal

    async function fetchData() {
      try {
        const result = await restApiClient.metrics.list(applicableVariables, { signal })
        if (!signal.aborted) {
          setData(result)
        }
      } catch (e) {
        if (!signal.aborted && e.name !== "AbortError") {
          setError(e)
        }
      } finally {
        if (!signal.aborted) {
          setLoading(false)
        }
      }
    }
    fetchData()

    return () => {
      abortController.abort()
    }
  }, [restApiClient, variablesString])

  return { loading, data, error }
}
