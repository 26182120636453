import dayjs from "dayjs"

import { useRestMetricsList } from "#Hooks/useRestMetricsList"

import { filterArrayWith } from "../../../../utils/Array"
import TextfieldWithAutoComplete from "../../../shared/TextfieldWithAutoComplete"

const TriggerFormHostname = ({ appId, hostname: givenHostname, onChange }) => {
  const hostname = givenHostname.trim()
  const { loading, data, error } = useRestMetricsList({
    variables: {
      site_id: appId,
      from: dayjs().subtract(10, "minute").utc().second(0).millisecond(0).toISOString(),
      to: dayjs().utc().second(0).millisecond(0).toISOString(),
      group_by: [{ Tag: "hostname" }],
      select: [
        {
          id: "load_average",
          name: "load_avg",
          tags: [
            { key: "hostname", value: "*" },
            { key: "host_metric", value: "" },
          ],
          field: "GAUGE",
        },
      ],
    },
  })

  const availableHosts = (data?.rows || []).map((r) => r.group.hostname)

  const withWildcard = hostname.includes("*")
  const message = autoCompleteMessage(loading, error)
  const unconfiguredHost = !hostname
  const matchedOptions = withWildcard
    ? filterArrayWith(hostname, availableHosts, "wildcardEqual")
    : availableHosts
  const unknownHost = !loading && !unconfiguredHost && matchedOptions.length === 0
  return (
    <div className="c-form">
      <label className="c-form__label" htmlFor="trigger_hostname">
        Hostname matches
      </label>
      <div className="c-form__element">
        <TextfieldWithAutoComplete
          id="trigger_hostname"
          name="trigger_hostname"
          message={message}
          value={hostname}
          placeholder="Metric"
          options={availableHosts}
          popOverHeader="Hosts from the last hour"
          hasWarning={unknownHost}
          hasError={unconfiguredHost}
          wildcardSupport={true}
          onSelect={(hostname) => onChange(hostname)}
        />
      </div>
      {unconfiguredHost && (
        <p className="mt-2 mb-0 c-form__error">No hostname configured. Please select a hostname.</p>
      )}
      {unknownHost && (
        <p className="mt-2 mb-0 c-p--sm text-orange-500">
          Selected host was not found in the last hour on page load. It might exist outside this
          timeframe and still trigger an alert.
        </p>
      )}
      <p className="c-form__description">
        Select a host using their hostname. Match multiple hosts by using a wildcard in the name (
        <code>*</code>). The default value of <code>*</code> matches all hostnames.
      </p>
    </div>
  )
}

TriggerFormHostname.propTypes = {
  appId: PropTypes.string.isRequired,
  hostname: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

function autoCompleteMessage(loading, error) {
  if (loading) {
    return "Loading hosts..."
  }
  if (error) {
    return "An error occurred while loading the hosts."
  }
}

export default TriggerFormHostname
