import gql from "graphql-tag"

export default gql `
  fragment AssigneeFragment on User {
    id
    name
    initials
    emailHash
    gravatarEnabled
  }
`
