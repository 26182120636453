import PropTypes from "prop-types"
import React, { useContext } from "react"

import { RouterContext } from "../../contexts/RouterContext"

const Link = (props) => {
  const router = useContext(RouterContext)

  const handleClick = (event) => {
    // If link is disabled, stop anything from happening
    if (props.disabled === true) {
      event.preventDefault()
      event.stopPropagation()
      return
    }

    if (props.onClick) {
      if (props.onClick(event) === false) {
        event.preventDefault()
        event.stopPropagation()
        return
      }
    }
    // If someone presses cmd/alt or the middle mouse button
    // leave it up to the browser to handle the url
    // (usually opens in  new window)
    if (!event.metaKey && !event.ctrlKey && !event.shiftKey && event.button !== 1) {
      event.preventDefault()
      event.stopPropagation()

      router.navigate(props.href, { trigger: true })
    }
  }

  const renderLabel = () => {
    if (props.children) {
      return props.children
    } else {
      return props.title
    }
  }
   
  const { onClick: _onClick, children: _children, ...linkProps } = props

  return (
    <a {...linkProps} onClick={handleClick}>{renderLabel()}</a>
  )
}

Link.propTypes = {
  href: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node
}

export default Link
