import Timeframes from "#Root/base/timeframes"
import { useRestClientContext } from "#Root/contexts/RestClientContext"
import { useRouter } from "#Root/hooks"
import { useQuery } from "#Root/utils/react-query-client"

const formatQueries = (queries) =>
  queries.map((query) => ({
    name: query.name,
    tags: query.tags,
    field: query.field,
    draw_null_as_zero: query.drawNullAsZero ?? true,
  }))

const Rest = (props) => {
  const restApiClient = useRestClientContext()
  const { getParams } = useRouter()
  const {
    pollInterval: givenPollInterval,
    queries,
    resolution: explicitResolution,
    timeframe,
  } = props
  let { from, to } = props
  const { appId, chartResolution: datePickerResolution } = getParams()

  const pollInterval = isNaN(givenPollInterval)
    ? Timeframes.pollIntervalFromTimeframe(timeframe)
    : givenPollInterval

  if (!from && !to && timeframe) {
    const range = Timeframes.fromToFromTimeframe(timeframe)
    from = range.from
    to = range.to
  }

  // If we are using a custom timeframe, we should use the resolution from the date picker
  const resolution = timeframe === "custom" ? datePickerResolution : explicitResolution

  const variables = {
    site_id: appId,
    from,
    to,
    resolution,
    select: formatQueries(queries),
  }

  const { data, isLoading, error } = useQuery({
    queryKey: ["metrics", variables],
    queryFn: () => restApiClient.metrics.timeseries(variables),
    refetchInterval: pollInterval,
  })

  // The following line throws if there's more than one child
  const container = React.Children.only(props.children)

  let timeseries = {}

  if (data) {
    const series = data.series.map((serie) => {
      return {
        ...serie,
        yMax: serie.max,
        yMin: serie.min,
        data: serie.data.map((point) => ({
          x: new Date(point.timestamp),
          y: point.value,
        })),
      }
    })

    const xMin = new Date(data.from)
    const xMax = new Date(data.to)

    timeseries = { ...data, series, xMin, xMax }
  }

  return React.cloneElement(container, {
    ...container.props,
    timeseries,
    loading: isLoading,
    error,
  })
}

Rest.propTypes = {
  queries: PropTypes.array.isRequired,
  resolution: PropTypes.string,
  pollInterval: PropTypes.number,
  children: PropTypes.node.isRequired,
  from: PropTypes.string,
  to: PropTypes.string,
  timeframe: PropTypes.string,
}

export default Rest
