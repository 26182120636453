import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import ReactDatePicker from "react-datepicker"

import { DatePickerContext } from "#Root/contexts/DatePickerContext"
import { useFormatterContext } from "#Root/contexts/FormatterContext"

import DatePickerButton from "./DatePickerButton"
import DatePickerContainer, { resolutionTypes } from "./DatePickerContainer"
dayjs.extend(localizedFormat)
dayjs.extend(timezone)
dayjs.extend(utc)

export const fullDateTime = (date, time, timeZone) => {
  const splittedTime = time.split(":")
  return date.tz(timeZone).hour(splittedTime[0]).minute(splittedTime[1]).second(splittedTime[2])
}

const DateRangePicker = ({ onChange = () => {}, value, button, hideMinutely = false }) => {
  const datePickerRef = React.createRef()
  const { timeZone } = useFormatterContext()

  const [resolution, setResolution] = React.useState(resolutionTypes.HOURLY)
  const [minutelyRangeWindow, setMinutelyRangeWindow] = React.useState(8)

  const [timestamp, setTimestamp] = React.useState(() => {
    const from = value.from
      ? dayjs(value.from).tz(timeZone).format("HH:mm:ss").split(" ")[0]
      : "00:00:00"
    const to = value.to ? dayjs(value.to).tz(timeZone).format("HH:mm:ss").split(" ")[0] : "23:59:59"
    return { from, to }
  })

  const [range, setRange] = React.useState({
    from: value.from ? dayjs(value.from).tz(timeZone) : dayjs(),
    to: value.to ? dayjs(value.to).tz(timeZone) : dayjs(),
  })

  const handleDateChange = ([startDate, endDate]) => {
    setRange({ from: startDate ? dayjs(startDate) : null, to: endDate ? dayjs(endDate) : null })
  }

  const handleApply = () => {
    const startDate = fullDateTime(range.from, timestamp.from, timeZone)
    const endDate = fullDateTime(range.to || range.from, timestamp.to, timeZone)

    setRange({ from: startDate, to: endDate })

    onChange({
      timeframe: "custom",
      start: startDate,
      end: endDate,
    })
    closeCalendar()
  }

  // Different from the original implementation, is that we don't reconstruct the date using `fullDateTime`, we have that information already
  const handleMinutelyApply = (startDate, endDate) => {
    setRange({ from: startDate, to: endDate })

    onChange({
      timeframe: "custom",
      start: startDate,
      end: endDate,
      chartResolution: "MINUTELY", // only set this for minutely resolution, allow default for hourly
    })
    closeCalendar()
  }

  const closeCalendar = () => datePickerRef.current.setOpen(false)

  const isSubmitDisabled = Object.values(timestamp).some((value) => !value)

  return (
    <DatePickerContext.Provider
      value={{ initialValue: value, range, setRange, timestamp, setTimestamp, fullDateTime }}
    >
      <ReactDatePicker
        selected={range.from ? range.from.toDate() : null}
        startDate={range.from ? range.from.toDate() : null}
        endDate={range.to ? range.to.toDate() : null}
        filterDate={(date) => date.getTime() <= new Date().getTime()}
        onChange={handleDateChange}
        previousMonthButtonLabel={<i className="far fa-fw fa-angle-left"></i>}
        nextMonthButtonLabel={<i className="far fa-fw fa-angle-right"></i>}
        popperClassName="z-40"
        popperPlacement="bottom-end"
        selectsRange
        shouldCloseOnSelect={false}
        ref={datePickerRef}
        showPopperArrow={false}
        customInput={button || <DatePickerButton />}
        calendarContainer={(props) => (
          <DatePickerContainer
            hideMinutely={hideMinutely}
            resolution={resolution}
            setResolution={setResolution}
            minutelyRangeWindow={minutelyRangeWindow}
            setMinutelyRangeWindow={setMinutelyRangeWindow}
            closeCalendar={closeCalendar}
            handleHourlyApply={handleApply}
            handleMinutelyApply={handleMinutelyApply}
            isSubmitDisabled={isSubmitDisabled}
            {...props}
          />
        )}
      />
    </DatePickerContext.Provider>
  )
}

DateRangePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    from: PropTypes.object,
    to: PropTypes.object,
  }),
  button: PropTypes.node,
  hideMinutely: PropTypes.bool,
}

DateRangePicker.Button = DatePickerButton

export default DateRangePicker
