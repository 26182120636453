import { useMutation, useQuery } from "@apollo/react-hooks"
import PropTypes from "prop-types"
import React, { useState } from "react"

import TriggerFormNotifiers from "#Components/triggers/form/fields/Notifiers"
import createMutation from "#Graphql/createUptimeMonitorMutation"
import deleteMutation from "#Graphql/deleteUptimeMonitorMutation"
import updateMutation from "#Graphql/updateUptimeMonitorMutation"
import uptimeMonitorFormQuery from "#Graphql/uptimeMonitorFormQuery"
import uptimeMonitorsQuery from "#Graphql/uptimeMonitorsQuery"
import LoadingAnimation from "#Root/ui/LoadingAnimation/LoadingAnimation"

import Ident from "../../utils/Ident"
import ErrorBox from "../shared/error_box"
import TooltipWithIcon from "../shared/TooltipWithIcon"
import ValidationErrors from "../shared/validation_errors"
import Headers from "./Headers"
import Regions from "./Regions"

const states = {
  new: {
    submitLabel: "Create new uptime monitor",
  },
  edit: {
    submitLabel: "Update uptime monitor",
  },
}

const UptimeMonitorForm = ({ appId, uptimeMonitorId, closeOverlay }) => {
  // State to keep track of the input and errors
  const [uptimeMonitor, setUptimeMonitor] = useState({
    name: "",
    url: "",
    description: "",
    notifierIds: [],
    warmupDuration: 1,
    headers: [],
    checkBodyContent: "",
    regions: ["EUROPE", "ASIA_PACIFIC", "NORTH_AMERICA", "SOUTH_AMERICA"],
  })
  const [mutationError, setMutationError] = useState(null)
  const onSuccess = (identMetric) => {
    setMutationError(null)
    closeOverlay()
    Ident.trackAction(identMetric)
  }
  const onError = (message, error) => {
     
    console.error(message, error)
    setMutationError(error)
  }

  // Mutations to create/update/delete the monitor
  const [createUptimeMonitor] = useMutation(createMutation, {
    onCompleted() {
      onSuccess("UptimeMonitorOverlayCreate")
    },
    onError(error) {
      onError("Error creating uptime monitor", error)
    },
    refetchQueries: [
      {
        query: uptimeMonitorsQuery,
        variables: { appId },
      },
    ],
  })
  const [updateUptimeMonitor] = useMutation(updateMutation, {
    onCompleted() {
      onSuccess("UptimeMonitorOverlayUpdate")
    },
    onError(error) {
      onError("Error updating uptime monitor", error)
    },
  })
  const [deleteUptimeMonitor] = useMutation(deleteMutation, {
    onCompleted() {
      onSuccess("UptimeMonitorOverlayDelete")
    },
    onError(error) {
      onError("Error removing uptime monitor", error)
    },
    refetchQueries: [
      {
        query: uptimeMonitorsQuery,
        variables: { appId },
      },
    ],
  })

  // Form handlers
  const stateKey = uptimeMonitorId ? "edit" : "new"
  const state = states[stateKey]
  const handleChange = (changes) => {
    setUptimeMonitor({ ...uptimeMonitor, ...changes })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const payload = { appId, uptimeMonitor, id: uptimeMonitorId }
    if (stateKey === "edit") {
      updateUptimeMonitor({ variables: { ...payload } })
    } else {
      createUptimeMonitor({ variables: { ...payload } })
    }
  }
  const handleDelete = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (window.confirm("Are you sure you want to delete this uptime monitor?")) {
      deleteUptimeMonitor({ variables: { id: uptimeMonitorId, appId } })
    }
  }

  const handleSelectionChange = (source, field, value) => {
    let target = [...source]

    if (target.find((v) => v == value)) {
      target = target.filter((r) => r != value)
    } else {
      target.push(value)
    }

    setUptimeMonitor({ ...uptimeMonitor, [field]: target })
  }

  const { data, loading, error } = useQuery(uptimeMonitorFormQuery, {
    variables: { appId, id: uptimeMonitorId },
    onCompleted: ({ app }) => {
      if (app.uptimeMonitor) {
        setUptimeMonitor({
          url: app.uptimeMonitor.url,
          name: app.uptimeMonitor.name,
          description: app.uptimeMonitor.description,
          notifierIds: app.uptimeMonitor.notifierIds,
          warmupDuration: app.uptimeMonitor.warmupDuration,
          checkBodyContent: app.uptimeMonitor.checkBodyContent || "",
          regions: app.uptimeMonitor.regions,
          headers: app.uptimeMonitor.headers.map(({ key, value }) => ({ key, value })), // Remove __typename field
        })
      }
    },
  })

  // Render states or form
  if (loading) {
    return <LoadingAnimation message="Loading data" />
  }
  if (error) {
    return <ErrorBox error={error} />
  }

  return (
    <div className="c-box mb-0 text-gray-800">
      <div className="c-box__header">
        {stateKey === "new" ? "New uptime monitor" : "Edit uptime monitor"}
      </div>
      {mutationError && <ValidationErrors error={mutationError} />}
      <form onSubmit={handleSubmit} className="p-5" acceptCharset="UTF-8">
        <fieldset>
          <div className="c-form input string required name">
            <label
              htmlFor="name"
              className="c-form__label ignore-old-css string required control-label"
            >
              Name
            </label>
            <div className="c-form__element">
              <div className="c-textfield">
                <input
                  type="text"
                  value={uptimeMonitor.name}
                  name="name"
                  onChange={(e) => handleChange({ name: e.target.value })}
                  id="name"
                  className="c-textfield__input string required"
                  placeholder="name"
                />
              </div>
              <p className="c-form__description">
                Short, descriptive names are best. (e.g. &quot;homepage&quot;, &quot;api&quot; etc.)
              </p>
            </div>
          </div>

          <div className="c-form input string required url">
            <label
              htmlFor="url"
              className="c-form__label ignore-old-css string required control-label"
            >
              URL
            </label>
            <div className="c-form__element">
              <div className="c-textfield">
                <input
                  type="text"
                  value={uptimeMonitor.url}
                  name="url"
                  onChange={(e) => handleChange({ url: e.target.value })}
                  id="url"
                  className="c-textfield__input string required"
                  placeholder="URL"
                />
              </div>
            </div>
            <p className="c-form__description">
              The full URL to the endpoint you&apos;d like to monitor. We consider an endpoint down
              if it returns anything but a response code in the 200 range.
            </p>
          </div>

          <Headers
            headers={uptimeMonitor.headers}
            onChange={(value) => handleChange({ headers: value })}
          />

          <div className="c-form input string checkBodyContent">
            <label
              htmlFor="checkBodyContent"
              className="c-form__label ignore-old-css string control-label"
            >
              Check for HTML body content
            </label>
            <div className="c-form__element">
              <div className="c-textfield">
                <input
                  type="text"
                  value={uptimeMonitor.checkBodyContent}
                  name="checkBodyContent"
                  onChange={(e) => handleChange({ checkBodyContent: e.target.value })}
                  id="checkBodyContent"
                  className="c-textfield__input string"
                  placeholder=""
                />
              </div>
              <p className="c-form__description">
                Check whether the (raw, unparsed) response body for the Uptime Monitor request
                contains this string. An error will be generated if the value is not present. Leave
                this field empty to disable the body content check.
              </p>
            </div>
          </div>

          <div className="c-form input string optional description">
            <label
              htmlFor="description"
              className="c-form__label ignore-old-css description optional control-label"
            >
              Description
            </label>
            <div className="c-form__element">
              <div className="c-textarea">
                <textarea
                  name="description"
                  value={uptimeMonitor.description}
                  onChange={(e) => handleChange({ description: e.target.value })}
                  id="description"
                  rows="10"
                  className="c-textarea__input"
                  placeholder="Description"
                />
              </div>
            </div>
            <p className="c-form__description">
              Describe what the monitor is for and what to do when downtime is detected, this
              description will be shown in the alert when we detect downtime.
            </p>
          </div>

          <Regions
            available={data.app ? data.app.uptimeMonitorRegions : []}
            onChange={(regionId) =>
              handleSelectionChange(uptimeMonitor.regions, "regions", regionId)
            }
            selected={new Set(uptimeMonitor.regions)}
          />

          <TriggerFormNotifiers
            hint="Select one or more notifiers to be notified when the endpoint is down."
            notifiers={{
              available: data.app ? data.app.notifiers : [],
              onChange: (notifierId) =>
                handleSelectionChange(uptimeMonitor.notifierIds, "notifierIds", notifierId),
              selected: new Set(uptimeMonitor.notifierIds),
            }}
          />

          <div className="c-form input string number warmupDuration">
            <label htmlFor="warmupDuration" className="c-form__label">
              Alert warm-up
              <TooltipWithIcon content={<p>How long AppSignal waits before opening an alert</p>} />
            </label>
            <div className="c-form__element">
              <div className="c-textfield">
                <input
                  id="warmupDuration"
                  className="c-textfield__input"
                  name="warmupDuration"
                  step="any"
                  type="number"
                  min="0"
                  value={uptimeMonitor.warmupDuration}
                  onChange={(e) => handleChange({ warmupDuration: parseInt(e.target.value) })}
                />
                <span className="c-textfield__suffix">minutes</span>
              </div>
            </div>
          </div>

          <hr className="bg-gray-200 -mx-4 mb-0 ignore-old-css" />

          <div className="flex justify-end pt-4">
            {stateKey == "edit" && (
              <a
                onClick={handleDelete}
                className="c-button c-button--sm cursor-pointer c-button--red mr-auto"
              >
                Remove uptime monitor
              </a>
            )}
            <input
              type="submit"
              value={state.submitLabel}
              name="commit"
              className="c-button c-button--sm ignore-old-css"
            />
          </div>
        </fieldset>
      </form>
    </div>
  )
}

UptimeMonitorForm.propTypes = {
  appId: PropTypes.string.isRequired,
  uptimeMonitorId: PropTypes.string,
  closeOverlay: PropTypes.func.isRequired,
}

export default UptimeMonitorForm
