import { useQuery } from "react-apollo"

import withDateRange from "#Root/hocs/date_range"

import SearchQuery from "../../graphql/search_query"
import withPersistedParams from "../../wrappers/persisted_params"
import ErrorBox from "../shared/error_box"
import BlankSlate from "./BlankSlate"
import AppFilter from "./filters/AppFilter"
import DateFilter from "./filters/DateFilter"
import KindFilter from "./filters/KindFilter"
import NamespaceFilter from "./filters/NamespaceFilter"
import SearchFieldWithRefinement from "./SearchFieldWithRefinement"
import SearchIndexTable from "./table"

const variablesFromParams = (params) => {
  const variables = {
    organizationSlug: params.organizationSlug,
    query: params.q,
  }
  if (params.timeframe === "custom") {
    variables.timerange = [params.from, params.to]
  }
  if (params.sampleType && params.sampleType != "") variables.sampleType = params.sampleType
  if (params.namespace && params.namespace != "") variables.namespace = params.namespace
  if (params.searchAppId && params.searchAppId !== "") variables.appId = params.searchAppId
  return variables
}

export const SearchIndex = ({ params, setParams, onDateChange }) => {
  const { loading, error, data } = useQuery(SearchQuery, { variables: variablesFromParams(params) })
  if (error) return <ErrorBox error={error} />

  return (
    <div className="p-6">
      <SearchFieldWithRefinement
        initialValue={params.q}
        onSubmit={(value) => setParams({ q: value })}
      />
      <div className="flex flex-wrap mt-4 mb-2">
        <div className="mr-2 mb-2">
          <AppFilter setParams={setParams} loading={loading} data={data} params={params} />
        </div>
        <div className="mr-2 mb-2">
          <NamespaceFilter setParams={setParams} loading={loading} data={data} params={params} />
        </div>
        <div className="mr-2 mb-2">
          <KindFilter setParams={setParams} params={params} />
        </div>
        <div className="mr-2 mb-2">
          <DateFilter onDateChange={onDateChange} params={params} />
        </div>
      </div>
      <hr className="-mx-6 mt-2 bg-gray-200" />
      {!params.q && <BlankSlate />}
      {params.q && (
        <SearchIndexTable
          params={params}
          loading={loading}
          results={data?.organization.search || []}
        />
      )}
    </div>
  )
}

SearchIndex.propTypes = {
  params: PropTypes.object.isRequired,
  setParams: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
}

export default withPersistedParams(withDateRange(SearchIndex), {
  timeframe: {
    url: true,
    cookie: true,
    default: "R4H",
    ignoreCookieFunc: (value) => value == "custom",
  },
  from: { url: true, cookie: false, default: undefined },
  to: { url: true, cookie: false, default: undefined },
  chartResolution: { url: true, cookie: false, default: undefined },
  q: { url: true, cookie: false, default: "" },
  namespace: { url: true, cookie: false, default: "" },
  sampleType: { url: true, cookie: false, default: "" },
  searchAppId: { url: true, cookie: false, default: "" },
})
