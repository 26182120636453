import Checkbox from "#Root/ui/Checkbox"

import Section from "./Section"

const ChartMarkers = ({ showMarkers, setShowMarkers }) => {
  return (
    <Section>
      <Section.Title>Markers & deploys bar</Section.Title>
      <Section.Content>
        <Checkbox
          id="show-markers"
          name="show-markers"
          className="px-1"
          label="Show markers bar"
          ariaLabel="Show markers bar"
          selected={showMarkers}
          setSelected={() => setShowMarkers(!showMarkers)}
        />
      </Section.Content>
    </Section>
  )
}

ChartMarkers.propTypes = {
  showMarkers: PropTypes.bool,
  setShowMarkers: PropTypes.func,
}

export default ChartMarkers
