import { useQuery } from "@apollo/react-hooks"

import { useChartSettingsContext } from "#Root/contexts/ChartSettingsContext"
import { useRouter } from "#Root/hooks"

import { MARKERS_HEIGHT, Y_AXIS_WIDTH } from "../constants"
import { useChartGraphContext } from "../contexts/GraphContext"
import CustomMarker from "./Markers/CustomMarker"
import DeployMarker from "./Markers/DeployMarker"
import NotificationMarker from "./Markers/NotificationMarker"

export const MarkersTimelineQuery = gql`
  query MarkersTimelineQuery($appId: String!, $start: DateTime!, $end: DateTime!) {
    app(id: $appId) {
      id
      markers(start: $start, end: $end) {
        ... on DeployMarker {
          __typename
          id
          createdAt
          shortRevision
          user
          gitCompareUrl
        }
        ... on NotificationMarker {
          __typename
          id
          createdAt
          message
        }
        ... on CustomMarker {
          __typename
          id
          createdAt
          icon
          message
        }
      }
    }
  }
`

export const MarkersBase = ({ markers, hasYAxis }) => {
  return (
    <div className="flex border-t border-gray-200 mt-2" style={{ height: MARKERS_HEIGHT }}>
      {hasYAxis && (
        <div
          className="items-center content-center text-xs text-gray-600 select-none"
          style={{ width: Y_AXIS_WIDTH }}
        >
          Markers
        </div>
      )}
      <div className="flex items-center relative flex-1">
        <div className="h-px bg-gray-300 w-full"></div>
        {markers}
      </div>
    </div>
  )
}

MarkersBase.propTypes = {
  markers: PropTypes.array.isRequired,
  hasYAxis: PropTypes.bool,
}

const Markers = () => {
  const { xScale, hasYAxis, domain } = useChartGraphContext()
  const { settings } = useChartSettingsContext()
  const { getParams } = useRouter()

  xScale.domain(domain.x)

  const { data } = useQuery(MarkersTimelineQuery, {
    variables: {
      appId: getParams().appId,
      start: xScale.domain()[0].toISOString(),
      end: xScale.domain()[1].toISOString(),
    },
    enabled: settings.showMarkers,
  })

  if (!settings.showMarkers) {
    return null
  }

  const markers = data?.app?.markers.map((marker) => {
    const spacingLeft = xScale(new Date(marker.createdAt))
    switch (marker.__typename) {
      case "NotificationMarker":
        return (
          <NotificationMarker
            key={`marker-${marker.id}`}
            marker={marker}
            spacingLeft={spacingLeft}
          />
        )
      case "CustomMarker":
        return (
          <CustomMarker key={`marker-${marker.id}`} marker={marker} spacingLeft={spacingLeft} />
        )
      default:
        return (
          <DeployMarker key={`marker-${marker.id}`} marker={marker} spacingLeft={spacingLeft} />
        )
    }
  })

  return <MarkersBase markers={markers || []} hasYAxis={hasYAxis} />
}

Markers.displayName = "Markers"

export default Markers
