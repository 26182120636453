import { useMutation, useQuery } from "@apollo/react-hooks"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import LinkToParams from "#Root/components/shared/LinkToParams"
import UrlHelper from "#Root/components/shared/url_helper"
import AppInfoQuery from "#Root/graphql/app_info_query"
import CreateDashboardMutation from "#Root/graphql/create_dashboard_mutation"
import CustomMetricsDashboardDefinitionQuery from "#Root/graphql/custom_metrics_dashboard_definition_query"
import UpdateDashboardMutation from "#Root/graphql/update_dashboard_mutation"
import { useRouter } from "#Root/hooks"
import Button from "#Root/ui/Button"
import LoadingAnimation from "#Root/ui/LoadingAnimation/LoadingAnimation"
import Overlay from "#Root/ui/Overlay"

const DashboardFormOverlayContent = ({ params }) => {
  const { router, navigateWithParams } = useRouter()

  const { appId, action, id: dashboardId } = params

  const [formState, setFormState] = useState({ title: "", description: "" })

  const [createDashboard, { loading: createLoading }] = useMutation(CreateDashboardMutation)
  const [updateDashboard, { loading: updateLoading }] = useMutation(UpdateDashboardMutation)

  const { data, loading } = useQuery(CustomMetricsDashboardDefinitionQuery, {
    skip: !dashboardId,
    variables: {
      appId,
      id: dashboardId,
    },
    fetchPolicy: "network-only", // Always fetch the latest data
  })

  const editDashboard = action === "edit"
  const formInvalid = formState.title.trim().length === 0
  const networkRequestPending = createLoading || updateLoading || loading

  useEffect(() => {
    if (editDashboard && !loading && data?.app?.dashboard) {
      setFormState({
        title: data.app.dashboard.title || "",
        description: data.app.dashboard.description || "",
      })
    }
  }, [editDashboard, loading, data])

  const handleFieldChange = (field) => (event) => {
    const newValue = event.target.value
    setFormState((prev) => ({ ...prev, [field]: newValue }))
  }

  const handleSubmit = async (event) => {
    event?.preventDefault()

    if (networkRequestPending || formInvalid) {
      return
    }

    try {
      if (editDashboard) {
        await updateDashboard({
          awaitRefetchQueries: true,
          variables: {
            appId,
            id: dashboardId,
            title: formState.title,
            description: formState.description,
          },
          refetchQueries: [{ query: AppInfoQuery, variables: { appId } }],
        })
        router.navigate(UrlHelper.metricsShowPath({ id: dashboardId }), { trigger: true })
      } else {
        const result = await createDashboard({
          awaitRefetchQueries: true,
          variables: {
            appId,
            title: formState.title,
            description: formState.description,
          },
          refetchQueries: [{ query: AppInfoQuery, variables: { appId } }],
        })
        const newDashboardId = result.data.createDashboard.id
        router.navigate(UrlHelper.metricsShowPath({ id: newDashboardId }), { trigger: true })
      }
    } catch (error) {
      console.error("Error submitting dashboard:", error)
    }
  }

  const handleClose = (event) => {
    if (event) event.preventDefault()
    navigateWithParams({ overlay: null, action: null })
  }

  const renderContent = () => {
    if (editDashboard && loading) {
      return (
        <div className="bg-white rounded h-[384px] flex items-center justify-center">
          <LoadingAnimation message="Loading your dashboard data" />
        </div>
      )
    } else {
      return (
        <form onSubmit={handleSubmit} className="bg-white rounded">
          <div className="border-b border-gray-200 -mx-5 -mt-5 mb-5 p-5 flex items-center">
            <h1 className="c-heading mb-0">Dashboard settings</h1>
            {!editDashboard && (
              <LinkToParams className="ml-auto" params={{ overlay: "dashboardImportOverlay" }}>
                Import dashboard
              </LinkToParams>
            )}
          </div>
          <div className="c-form">
            <label className="c-form__label">Title</label>
            <div className="c-form__element">
              <div className="c-textfield">
                <input
                  type="text"
                  className="c-textfield__input"
                  placeholder="Title"
                  value={formState.title}
                  onChange={handleFieldChange("title")}
                />
              </div>
            </div>
            <p className="c-form__description">
              Use a <code className="c-inline-code">/</code> to create (one) sub-group of
              dashboards. <br /> For example:{" "}
              <code className="c-inline-code">MongoDB/Query Load</code>.
            </p>
          </div>

          <div className="c-form mb-0">
            <label className="c-form__label" htmlFor="">
              Description
            </label>
            <span className="c-form__hint">optional</span>
            <div className="c-form__element">
              <div className="c-textarea">
                <textarea
                  type="text"
                  className="c-textarea__input"
                  placeholder="Description"
                  rows="4"
                  value={formState.description}
                  onChange={handleFieldChange("description")}
                />
              </div>
            </div>
          </div>
          <Overlay.Actions>
            <Button
              disabled={formInvalid || networkRequestPending}
              onClick={handleSubmit}
              type="submit"
            >
              {editDashboard ? "Update dashboard" : "Create dashboard"}
            </Button>
            <Button color="white" onClick={handleClose}>
              Cancel
            </Button>
          </Overlay.Actions>
        </form>
      )
    }
  }

  return (
    <Overlay open={true} onClose={handleClose} size="sm">
      <Overlay.Title
        title={editDashboard ? "Edit dashboard" : "New dashboard"}
        handleClose={handleClose}
      />
      <Overlay.Content>{renderContent()}</Overlay.Content>
    </Overlay>
  )
}

const DashboardFormOverlay = ({ params, open }) => {
  // Only render the form component when the overlay is open
  if (!open) return null

  return <DashboardFormOverlayContent params={params} />
}

DashboardFormOverlay.propTypes = {
  open: PropTypes.bool,
  params: PropTypes.object.isRequired,
}

DashboardFormOverlayContent.propTypes = {
  params: PropTypes.object.isRequired,
}

export default DashboardFormOverlay
