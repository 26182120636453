import dayjs from "dayjs"
import minMax from "dayjs/plugin/minMax"
dayjs.extend(minMax)

export function setTimestampsFromParams(params) {
  const { timeframe, start, end, from, to, chartResolution } = params

  if (timeframe && timeframe !== "custom") {
    return {
      timeframe,
      from: null,
      to: null,
      chartResolution: null, // clear the chart resolution
    }
  } else {
    // ? This is to support the legacy Datetime filter.
    // ? Once we have fully removed that one we can drop the start/end support
    if (start && end) {
      return {
        timeframe: "custom",
        from: start.toISOString(),
        to: end.toISOString(),
        chartResolution,
      }
    } else {
      return {
        timeframe: "custom",
        from,
        to,
        chartResolution,
      }
    }
  }
}

export function parseTimestampFromURL(timestamp) {
  if (dayjs.isDayjs(timestamp)) {
    return timestamp
  } else {
    return dayjs(decodeURIComponent(timestamp))
  }
}

export function chunkTimeline(start, end, durationInSeconds = 86400) {
  const chunks = []
  let startTimestamp = dayjs(start)
  const endTimestamp = dayjs(end)

  while (startTimestamp.isBefore(endTimestamp)) {
    let nextEnd = startTimestamp.add(durationInSeconds, "second")

    if (nextEnd.isAfter(endTimestamp)) {
      nextEnd = endTimestamp
    }

    chunks.push({ start: startTimestamp.toISOString(), end: nextEnd.toISOString() })
    startTimestamp = nextEnd
  }

  return chunks
}

function hasMultipleChunks(start, end, queryWindow) {
  const startOfMinute = dayjs(start).startOf("minute")
  const duration = dayjs(end).diff(startOfMinute, "second")
  return duration > queryWindow
}

function getResolution(start, end) {
  const duration = dayjs(end).diff(dayjs(start), "second")
  return duration < 24 * 60 * 60 ? "MINUTELY" : "HOURLY"
}

export function chunkByWindow({ start, end, queryWindow }) {
  const resolution = getResolution(start, end)

  if (!hasMultipleChunks(start, end, queryWindow)) {
    return {
      chunks: [{ start: dayjs(start).startOf("minute").toISOString(), end }],
      resolution,
    }
  }

  const chunks = []
  let startTimestamp = dayjs(start)
  const endTimestamp = dayjs(end)

  while (startTimestamp.isBefore(endTimestamp)) {
    const nextEnd = startTimestamp.add(queryWindow, "second")

    if (nextEnd.isAfter(endTimestamp)) {
      chunks.push({
        start: startTimestamp.toISOString(),
        end: endTimestamp.toISOString(),
      })
      break
    }

    // For `HOURLY` resolution, subtract an hour from the end
    // For `MINUTELY` resolution, subtract a minute from the end
    const adjustedEnd =
      resolution === "HOURLY" ? nextEnd.subtract(1, "hour") : nextEnd.subtract(1, "minute")

    chunks.push({
      start: startTimestamp.toISOString(),
      end: adjustedEnd.toISOString(),
    })

    startTimestamp = nextEnd
  }

  return { chunks, resolution }
}

export function maxRange(firstRange, secondRange) {
  // Turn the ranges into an array, filter out nulls, and map to a dayjs object we can compare
  const days = [firstRange.start, firstRange.end, secondRange.start, secondRange.end]
    .filter((r) => r)
    .map((i) => dayjs(i))

  return { start: dayjs.min(days).toISOString(), end: dayjs.max(days).toISOString() }
}
