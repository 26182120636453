import dayjs from "dayjs"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

import Timeframes from "#Root/base/timeframes"
import { useRestClientContext } from "#Root/contexts/RestClientContext"
import { useRouter } from "#Root/hooks"
import { chunkByWindow } from "#Root/utils/datetime"

const FORWARD_TIME_IN_SECONDS = 60

const formatQueries = (queries) =>
  queries.map((query) => ({
    name: query.name,
    tags: query.tags,
    field: query.field,
    draw_null_as_zero: query.drawNullAsZero ?? true,
  }))

const ChunkedRest = (props) => {
  const restApiClient = useRestClientContext()
  const { getParams } = useRouter()
  const { pollInterval: givenPollInterval, queries, timeframe, queryWindow } = props
  let { from, to } = props
  const { appId } = getParams()

  const [combinedData, setCombinedData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  const abortControllerRef = useRef(null)

  const pollInterval = isNaN(givenPollInterval)
    ? Timeframes.pollIntervalFromTimeframe(timeframe)
    : givenPollInterval

  if (!from && !to && timeframe) {
    const range = Timeframes.fromToFromTimeframe(timeframe)
    from = range.from
    to = range.to
  }

  const stringifiedQueries = JSON.stringify(queries)
  const queryParams = React.useMemo(
    () => ({
      from,
      to,
      appId,
      queries: stringifiedQueries,
    }),
    [from, to, appId, stringifiedQueries],
  )

  useEffect(() => {
    const fetchChunkedData = async () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
      abortControllerRef.current = new AbortController()
      const signal = abortControllerRef.current.signal

      const { chunks, resolution } = chunkByWindow({
        start: queryParams.from,
        end: queryParams.to,
        queryWindow,
      })

      const partialResults = []

      // Get two chunks at the same time
      for (let i = 0; i < chunks.length; i += 2) {
        if (signal.aborted) {
          return
        }

        const chunk1 = chunks[i]
        const chunk2 = i + 1 < chunks.length ? chunks[i + 1] : null // ignore if there is no second chunk

        const fetchChunk = async (chunk) => {
          if (!chunk) return null

          const variables = {
            site_id: queryParams.appId,
            from: chunk.start,
            to: chunk.end,
            resolution: resolution,
            select: formatQueries(JSON.parse(queryParams.queries)),
          }

          try {
            return await restApiClient.metrics.timeseries(variables, { signal })
          } catch (e) {
            if (e.name === "AbortError") {
              setIsLoading(false)
            } else {
              console.error(e)
              setError(e)
              setIsLoading(false)
            }
            return null
          }
        }

        // Run both at the same time
        const [result1, result2] = await Promise.all([fetchChunk(chunk1), fetchChunk(chunk2)])

        if (result1) {
          partialResults.push(result1)
        }
        if (result2) {
          partialResults.push(result2)
        }

        let combinedResult = null
        try {
          combinedResult = combineChunkResults(partialResults)
          setCombinedData(combinedResult)
        } catch (e) {
          console.error(e)
          setIsLoading(false)
          setError(e)
          return
        }

        // Marked the query as finished if we have some data or if we are at the last chunk
        if ((combinedResult && combinedResult.series.length !== 0) || i >= chunks.length - 2) {
          setIsLoading(false)
        }
      }
    }

    // Ensures that it won't run the fetching/chunking requests unless the component completely mounts
    // Unless the parent completely unmounts due to different props.
    if (!abortControllerRef.current) {
      fetchChunkedData()
    }

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
    }
  }, [queryParams, restApiClient, queryWindow])

  useEffect(() => {
    if (pollInterval === 0 || !combinedData) {
      return
    }

    const pollData = async () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
      abortControllerRef.current = new AbortController()
      const signal = abortControllerRef.current.signal

      const newStart = dayjs(combinedData.from)
        .add(FORWARD_TIME_IN_SECONDS, "seconds")
        .toISOString()
      const newEnd = dayjs(combinedData.to).add(FORWARD_TIME_IN_SECONDS, "seconds").toISOString()

      const variables = {
        site_id: queryParams.appId,
        from: newStart,
        to: newEnd,
        resolution: "MINUTELY",
        select: formatQueries(JSON.parse(queryParams.queries)),
      }

      try {
        const result = await restApiClient.metrics.timeseries(variables, { signal })

        if (!signal.aborted) {
          // Directly replace the current data with the new result
          // We only poll for ranges smaller than a day, so we have no chunking
          setCombinedData(result)
        }
      } catch (e) {
        if (e.name !== "AbortError") {
          console.error("Polling error:", e)
        }
      }
    }

    const intervalId = setInterval(pollData, pollInterval)

    return () => {
      clearInterval(intervalId)
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
    }
  }, [pollInterval, combinedData, queryParams, restApiClient])

  let timeseries = {}

  if (combinedData) {
    const series = combinedData.series.map((serie) => ({
      ...serie,
      yMax: serie.max,
      yMin: serie.min,
      data: serie.data.map((point) => ({
        x: new Date(point.timestamp),
        y: point.value,
      })),
    }))

    const xMin = new Date(combinedData.from)
    const xMax = new Date(combinedData.to)

    timeseries = { ...combinedData, series, xMin, xMax }
  }

  const container = React.Children.only(props.children)

  return React.cloneElement(container, {
    ...container.props,
    timeseries,
    loading: isLoading,
    error,
  })
}

ChunkedRest.propTypes = {
  queries: PropTypes.array.isRequired,
  pollInterval: PropTypes.number,
  children: PropTypes.node.isRequired,
  from: PropTypes.string,
  to: PropTypes.string,
  timeframe: PropTypes.string,
}

export default ChunkedRest

export const combineChunkResults = (chunkResults) => {
  if (chunkResults.length === 0) {
    return null
  }

  const firstChunk = chunkResults[0]
  const lastChunk = chunkResults[chunkResults.length - 1]

  const combinedSeries = {}
  const allTimestamps = new Set()

  // Collect all unique timestamps from all chunks
  chunkResults.forEach((chunk) => {
    if (chunk.series.length !== 0) {
      // If the chunk has populated series, take the timestamps from the first series
      chunk.series[0].data.forEach((dataPoint) => {
        allTimestamps.add(dataPoint.timestamp)
      })
    } else {
      // If the chunk has empty series, generate timestamps based on resolution.
      // Otherwise, we might end up with missing data points and an malformed chart.
      let current = dayjs(chunk.from)
      const end = dayjs(chunk.to)
      const increment = chunk.resolution === "MINUTELY" ? 1 : 60 // 1 minute or 60 minutes (1 hour)

      while (current.isBefore(end) || current.isSame(end)) {
        allTimestamps.add(current.toISOString())
        current = current.add(increment, "minute")
      }
    }
  })

  chunkResults.forEach((chunk) => {
    chunk.series.forEach((serie) => {
      const serieId = serie.id

      // New serie, that we haven't seen before
      // Initialize it with empty data
      if (!combinedSeries[serieId]) {
        combinedSeries[serieId] = { ...serie, data: [] }
      }

      serie.data.forEach((dataPoint) => {
        combinedSeries[serieId].data.push(dataPoint)
      })

      combinedSeries[serieId].max = Math.max(combinedSeries[serieId].max, serie.max)
      combinedSeries[serieId].min = Math.min(combinedSeries[serieId].min, serie.min)
    })
  })

  // Let's run a second pass and populate the missing dates, with 0 values
  Object.values(combinedSeries).forEach((serie) => {
    const serieTimestamps = new Set(serie.data.map((dataPoint) => dataPoint.timestamp))
    Array.from(allTimestamps).forEach((timestamp) => {
      if (!serieTimestamps.has(timestamp)) {
        serie.data.push({ timestamp, value: 0 })
      }
    })
  })

  // Sort the data points by timestamp
  Object.values(combinedSeries).forEach((serie) => {
    serie.data.sort((a, b) => a.timestamp.localeCompare(b.timestamp))
  })

  return {
    resolution: firstChunk.resolution,
    from: firstChunk.from,
    to: lastChunk.to,
    series: Object.values(combinedSeries),
  }
}
